import React, { FC, useEffect, useState } from "react";
import CardsStyle from "./Style";
import { Grid, Typography } from "@mui/material";
import useCardsService from "../../services/Cards";
import { CardsModel, TokenURIsModel } from "../../models/card";
import CardItem from "./item/Component";

type Props = {
  URIs: TokenURIsModel;
};

const Cards: FC<Props> = ({ URIs }) => {
  const { getAllCardsData } = useCardsService();
  const [cards, setCards] = useState<CardsModel>({
    normal: [],
    special: [],
    dinner: [],
    commemorativeDinner: [],
    oneOfOne: [],
  });

  useEffect(() => {
    (async () => {
      const response = await getAllCardsData(URIs);
      setCards(response);
    })();
  }, [URIs]);

  return (
    <CardsStyle>
      <Grid container justifyContent={"center"} spacing={4} className="cards-container">
        {cards.normal.length > 0 && (
          <Grid item xxxs={12}>
            <Typography variant="h2" sx={{ textAlign: "center" }} className="cards-title">
              Trump Digital Trading Cards America First Edition Card(s)
            </Typography>
          </Grid>
        )}
        {cards.normal.map((item, idx) => {
          return <CardItem key={"main" + idx} name={item.name} img={item.image} idx={idx} />;
        })}
        {cards.special.length > 0 && (
          <Grid item xxxs={12}>
            <Typography variant="h2" sx={{ textAlign: "center" }} className="cards-title">
              Trump Digital Trading Cards Debate Bonus Edition Card(s)
            </Typography>
          </Grid>
        )}
        {cards.special.map((item, idx) => {
          return <CardItem key={"special" + idx} name={item.name} img={item.image} idx={idx} />;
        })}
        {cards.dinner.length > 0 && (
          <Grid item xxxs={12}>
            <Typography variant="h2" sx={{ textAlign: "center" }} className="cards-title">
              Trump Digital Trading Cards America First Dinner Ticket Card(s)
            </Typography>
          </Grid>
        )}
        {cards.dinner.map((item, idx) => {
          return <CardItem key={"dinner" + idx} name={item.name} img={item.image} idx={idx} />;
        })}

        {cards.commemorativeDinner.length > 0 && (
          <Grid item xxxs={12}>
            <Typography variant="h2" sx={{ textAlign: "center" }} className="cards-title">
              Trump Digital Trading Cards America First Commemorative Dinner NFT
            </Typography>
          </Grid>
        )}
        {cards.commemorativeDinner.map((item, idx) => {
          return <CardItem key={"dinner" + idx} name={item.name} img={item.image} idx={idx} />;
        })}

        {cards.oneOfOne.length > 0 && (
          <Grid item xxxs={12}>
            <Typography variant="h2" sx={{ textAlign: "center" }} className="cards-title">
              Trump Digital Trading Cards America First 1-of-1 Bonus Collection Card(s)
            </Typography>
          </Grid>
        )}
        {cards.oneOfOne.map((item, idx) => {
          return <CardItem key={"dinner" + idx} name={item.name} img={item.image} idx={idx} />;
        })}
      </Grid>
    </CardsStyle>
  );
};

export default Cards;
