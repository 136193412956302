import { ethers } from "ethers";
import { useStoreContext } from "../hooks/Contexts";
import { useState } from "react";
import { toast } from "react-toast";
import {
  collectTrumpCardsSite,
  minterAddress,
  tokenAddress,
  specialTokenAddress,
  dinnerTokenAddress,
  mainCollectionTokenUri,
  specialCollectionTokenUri,
  dinnerCollectionTokenUri,
  zoomAddress,
  commemorativeDinnerAddress,
  oneOfOneAddress,
} from "../utils/constants";
import { MINTER_ABI, TOKEN_ABI } from "../utils/abi";
import { Zoom2 } from "zoom-next";

const useContractService = () => {
  const { store, setStore } = useStoreContext();
  const [loading, setLoading] = useState(false);

  const create = (addr: string, abi: any, providerOrSigner: any) => {
    return new ethers.Contract(addr, abi, providerOrSigner);
  };

  const setpolygonScanHash = (hash: string) => {
    setStore((prevState) => ({
      ...prevState,
      cart: {
        ...prevState.cart,
        polygonScanHash: hash,
      },
    }));
  };

  const purchasedWithCreditCardByWalletAddress = async (): Promise<boolean | undefined> => {
    const minterContract = create(minterAddress!, MINTER_ABI, store.web3Auth.web3AuthProvider);
    try {
      return await minterContract.purchasedWithCreditCard(store.cart.user.wallet);
    } catch (error) {
      console.log(error);
    }
    return undefined;
  };

  const getTokenBalanceOfByWalletAddress = async (): Promise<number | undefined> => {
    const tokenContract = create(tokenAddress!, TOKEN_ABI, store.web3Auth.web3AuthProvider);
    try {
      const balance = await tokenContract.balanceOf(store.cart.user.wallet);
      return Number(balance);
    } catch (error) {
      console.log(error);
    }
    return undefined;
  };

  const approveWeth = async (
    wethContract: any,
    minterContractAddress: any,
    totalPriceToApprove: any
  ) => {
    try {
      const allowance = await wethContract.allowance(store.cart.user.wallet, minterContractAddress);
      if (parseInt(allowance.toString()) < parseInt(totalPriceToApprove.toString())) {
        const transaction = await wethContract.approve(minterContractAddress, totalPriceToApprove);
        setpolygonScanHash(transaction?.hash);
        await transaction.wait();
      }
      const newAllowance = await wethContract.allowance(
        store.cart.user.wallet,
        minterContractAddress
      );

      if (parseInt(newAllowance.toString()) < parseInt(totalPriceToApprove.toString())) {
        toast.error("Approve more wETH!");
        await approveWeth(wethContract, minterContractAddress, totalPriceToApprove);
      } else {
        return true;
      }
    } catch (error) {
      //@ts-ignore
      toast.error(error?.message);
      throw new Error("error while approving");
    }
  };

  const mint = async (
    minterContract: any,
    minterContractAddress: any,
    wethContract: any,
    userNonce: any,
    signature: any,
    saleDomain: any,
    contractPrice: any,
    contractPriceInEther: number
  ) => {
    try {
      setLoading(true);
      await approveWeth(wethContract, minterContractAddress, contractPrice);

      const wethBalance = await wethContract.balanceOf(store.cart.user.wallet);
      if (wethBalance < contractPriceInEther) {
        toast.error("You don`t have enough wETH to mint!!!");
        setLoading(false);
        return;
      }
      const estimateGas = await minterContract.buyPackage.estimateGas(
        {
          saleDomain: saleDomain,
          buyer: store.cart.user.wallet,
          price: contractPrice,
          numberOfTokens: store.cart.offer?.numberOfTokens,
          packageType: store.cart.offer?.packageType,
          userNonce: userNonce,
        },
        signature
      );

      const finalGasLimit = (BigInt(estimateGas) * BigInt(105)) / BigInt(100);

      const mintTransaction = await minterContract.buyPackage(
        {
          saleDomain: saleDomain,
          buyer: store.cart.user.wallet,
          price: contractPrice,
          numberOfTokens: store.cart.offer?.numberOfTokens,
          packageType: store.cart.offer?.packageType,
          userNonce: userNonce,
        },
        signature,
        {
          gasLimit: finalGasLimit,
        }
      );
      setpolygonScanHash(mintTransaction?.hash);
      await mintTransaction.wait();
      setLoading(false);
      window.location.href = `${collectTrumpCardsSite}/thank-you?nftAmount=${store.cart.offer?.numberOfTokens}`;
      // TODO: navigate to the congrats page
    } catch (error) {
      setLoading(false);
      //@ts-ignore
      toast.error(error.message);
      console.log(error, " error");
    }
  };

  const getUsersMints = async (minterContract: any) => {
    const mintNumber = await minterContract.mintsByAddress(store.cart.user.wallet);
    return mintNumber;
  };

  const getTokenUris = async (userAddress: string) => {
    setLoading(true);
    console.log("Adddr", userAddress);
    console.log("Provider", store.web3Auth.web3AuthProvider);

    let mainTokenURIs: any = [];
    let specialTokenURIs: any = [];
    let dinnerTokenURIs: any = [];
    let commemorativeDinnerTokenURIs: any = [];
    let oneOfoneBonusURIs: any = [];

    const ZoomLibraryInstance = new Zoom2();
    const ZoomContractInstance = new ethers.Contract(
      zoomAddress!,
      ZoomLibraryInstance.zoomABI,
      store.web3Auth.web3AuthProvider
    );

    const mainToken = create(tokenAddress!, TOKEN_ABI, store.web3Auth.web3AuthProvider);
    const specialToken = create(specialTokenAddress!, TOKEN_ABI, store.web3Auth.web3AuthProvider);
    const dinnerToken = create(dinnerTokenAddress!, TOKEN_ABI, store.web3Auth.web3AuthProvider);
    const commemorativeDinnerToken = create(
      commemorativeDinnerAddress!,
      TOKEN_ABI,
      store.web3Auth.web3AuthProvider
    );
    const oneOfOneToken = create(oneOfOneAddress!, TOKEN_ABI, store.web3Auth.web3AuthProvider);

    if (mainToken && specialToken && dinnerToken && commemorativeDinnerToken && oneOfOneToken) {
      const balanceMain = ZoomLibraryInstance.addCall(
        mainToken,
        tokenAddress!,
        ["balanceOf(address owner)", [userAddress]],
        "function balanceOf(address target) external view returns (uint256)"
      );
      const balanceSpecial = ZoomLibraryInstance.addCall(
        specialToken,
        specialTokenAddress!,
        ["balanceOf(address owner)", [userAddress]],
        "function balanceOf(address target) external view returns (uint256)"
      );
      const balanceDinner = ZoomLibraryInstance.addCall(
        dinnerToken,
        dinnerTokenAddress!,
        ["balanceOf(address owner)", [userAddress]],
        "function balanceOf(address target) external view returns (uint256)"
      );

      const balanceCommemorativeDinner = ZoomLibraryInstance.addCall(
        commemorativeDinnerToken!,
        commemorativeDinnerAddress!,
        ["balanceOf(address owner)", [userAddress]],
        "function balanceOf(address target) external view returns (uint256)"
      );

      const balanceOneOfOne = ZoomLibraryInstance.addCall(
        oneOfOneToken,
        oneOfOneAddress!,
        ["balanceOf(address owner)", [userAddress]],
        "function balanceOf(address target) external view returns (uint256)"
      );

      await ZoomLibraryInstance.runZoomCallAndFulfillPromises(
        ZoomContractInstance,
        true,
        console.log
      );

      const mainBalance = await balanceMain;
      const specialBalance = await balanceSpecial;
      const dinnerBalance = await balanceDinner;
      const commemorativeBalance = await balanceCommemorativeDinner;
      const oneOfOneBalance = await balanceOneOfOne;

      //  console.log("balance A", mainBalance, specialBalance, dinnerBalance);

      mainTokenURIs = await doChunkedZoomCalls(
        ZoomLibraryInstance,
        ZoomContractInstance,
        mainToken,
        tokenAddress,
        userAddress,
        mainBalance,
        mainCollectionTokenUri
      );
      specialTokenURIs = await doChunkedZoomCalls(
        ZoomLibraryInstance,
        ZoomContractInstance,
        specialToken,
        specialTokenAddress,
        userAddress,
        specialBalance,
        specialCollectionTokenUri
      );
      dinnerTokenURIs = await doChunkedZoomCalls(
        ZoomLibraryInstance,
        ZoomContractInstance,
        dinnerToken,
        dinnerTokenAddress,
        userAddress,
        dinnerBalance,
        dinnerCollectionTokenUri
      );

      commemorativeDinnerTokenURIs = await doChunkedZoomCalls(
        ZoomLibraryInstance,
        ZoomContractInstance,
        commemorativeDinnerToken,
        commemorativeDinnerAddress,
        userAddress,
        commemorativeBalance
      );

      oneOfoneBonusURIs = await doChunkedZoomCalls(
        ZoomLibraryInstance,
        ZoomContractInstance,
        oneOfOneToken,
        oneOfOneAddress,
        userAddress,
        oneOfOneBalance
      );
    }

    setLoading(false);
    return {
      normal: mainTokenURIs,
      special: specialTokenURIs,
      dinner: dinnerTokenURIs,
      commemorativeDinner: commemorativeDinnerTokenURIs,
      oneOfOne: oneOfoneBonusURIs,
    };
  };

  const doChunkedZoomCalls = async (
    zoomLibInstance: any,
    zoomContractInstance: any,
    contract: any,
    contractAddress: any,
    ownerAdddress: any,
    tokenBalance: any,
    baseUri?: any
  ) => {
    const CHUNK_SIZE = 250;
    const chunks = Math.ceil(parseInt(tokenBalance) / CHUNK_SIZE);

    const tokenIDs = [];
    const tokenURIs = [];

    for (let i = 0; i < chunks; i++) {
      console.log("chunk", i);
      for (let j = 0; j < CHUNK_SIZE; j++) {
        let idx = i * CHUNK_SIZE + j;
        if (idx < parseInt(tokenBalance)) {
          // console.log("idx", idx);
          tokenIDs[idx] = zoomLibInstance.addCall(
            contract,
            contractAddress!,
            ["tokenOfOwnerByIndex(address owner, uint256 id)", [ownerAdddress, idx]],
            "function tokenOfOwnerByIndex(address owner, uint256 id) external view returns (uint256)"
          );
        }
      }
      await zoomLibInstance.runZoomCallAndFulfillPromises(zoomContractInstance, true, console.log);
    }

    let BASE_URI = baseUri
      ? baseUri
      : await contract.tokenBaseURI().catch((e: any) => console.log(e));

    for (let i = 0; i < parseInt(tokenBalance); i++) {
      let id: BigInt = await tokenIDs[i];
      let tokenURI = BASE_URI.indexOf("http") === -1 ? "https://" + BASE_URI : BASE_URI;

      if (BASE_URI.indexOf("dinner") === -1 && BASE_URI.indexOf("commemorative") === -1) {
        let folder = Math.floor(Number(id) / 1000);
        tokenURI = `${tokenURI}/${folder}/${Number(id)}.json`;
      }
      tokenURIs.push(tokenURI);
    }

    return tokenURIs;
  };

  return {
    create,
    mint,
    getUsersMints,
    loading,
    purchasedWithCreditCardByWalletAddress,
    getTokenBalanceOfByWalletAddress,
    getTokenUris,
  };
};

export default useContractService;
